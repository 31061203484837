
import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'
import { startNewQuestion } from './checklist-utils/startNewQuestion'
import QuestionEditDeleteReposition from './QuestionEditDeleteReposition'
import IndividualQuestionDetail from './IndividualQuestionDetail'
import { sortQuestionIdsByOrder } from './checklist-utils/sortQuestionIdsByOrder'

type Props = {
    question: ObjectStringKeyAnyValue
    savingProgress: boolean
    setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}
type SingleAnswerActionProps = {
    question: ObjectStringKeyAnyValue,
    actionId: string
    savingProgress: boolean
    setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}


function SingleAnswerAction({
    question,
    actionId,
    savingProgress,
    setSavingProgress
}: SingleAnswerActionProps) {

    const {
        tableData,
        setChecklistQuestionFields,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const navigate = useNavigate()
    const answerAction = question['AnswerActions'][actionId]
    const parentQuestionId = question['Id']
    const actionToTake = answerAction['ActionToTake']
    let actionAnswer = ''
    if (answerAction['ActionForAnswer']['equals']) {
        actionAnswer = answerAction['ActionForAnswer']['equals']
    } else if (answerAction['ActionForAnswer']['min'] && answerAction['ActionForAnswer']['max']) {
        actionAnswer = `Under ${answerAction['ActionForAnswer']['min']} or over ${answerAction['ActionForAnswer']['max']}`
    }
    const actionToTakeDisplayName = tableData['Organisation']['ChecklistActionOptions'][actionToTake]['displayName'] || actionToTake


    const followUpQuestionsForThisParent: ObjectStringKeyAnyValue = {}
    if (tableData && tableData['ChecklistQuestions']) {
        for (const questionId in tableData['ChecklistQuestions']) {
            const thisQuestion = tableData['ChecklistQuestions'][questionId]
            if (thisQuestion['parentQuestionId'] === parentQuestionId && thisQuestion['parentActionId'] === actionId) {
                followUpQuestionsForThisParent[questionId] = thisQuestion
            }
        }
    }
    const sortedFollowUpQuestions = sortQuestionIdsByOrder(followUpQuestionsForThisParent)

    // console.log(followUpQuestionsForThisParent)
    // console.log(sortedFollowUpQuestions)




    return <div className={`w-full bg-white px-2 py-1 rounded flex flex-col gap-1`}>
        <div className={`w-full flex flex-row items-center gap-2 justify-between`}>
            <p className={``}>
                If answer is <span className={`font-bold`}>{actionAnswer}</span>: {actionToTakeDisplayName}
            </p>

            {actionToTake === 'followUpQuestion' &&
                <Button
                    onClick={() => startNewQuestion(checklistId, setChecklistQuestionFields, navigate, parentQuestionId, actionId)}
                    text={`Add question`}
                    variant={`primary`}
                    size={`small`}
                    fullwidth={false}
                />
            }

        </div>

        {actionToTake === 'followUpQuestion' && sortedFollowUpQuestions.length > 0 &&
            <div className={`w-full flex flex-col gap-1`}>
                {sortedFollowUpQuestions.map((followUpQuestionId: string, index: number) => {
                    const followUpQuestion = tableData['ChecklistQuestions'][followUpQuestionId]
                    return <IndividualQuestionDetail
                        key={index}
                        question={followUpQuestion}
                        questionId={followUpQuestionId}
                        showEditButton={true}
                        savingProgress={savingProgress}
                        setSavingProgress={setSavingProgress}
                        isFirst={index === 0 ? true : false}
                        isLast={(index + 1) === sortedFollowUpQuestions.length ? true : false}
                    />
                })}
            </div>}

    </div>
}





function AnswerActionSummary({
    question,
    savingProgress,
    setSavingProgress
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const [showAll, setShowAll] = useState(true)

    return <div className={`flex flex-row items-start gap-1`}>






        <div onClick={() => setShowAll(!showAll)}
            className={`hover:opacity-80 cursor-pointer`}>
            {showAll ? '⬇️' : '➡️'}
        </div>


        <div className={`flex flex-col gap-1 w-full`}>


            <p className={`hover:opacity-80 cursor-pointer font-bold`} onClick={() => setShowAll(!showAll)}>
                {Object.keys(question['AnswerActions']).length} answer action{Object.keys(question['AnswerActions']).length > 1 && 's'}
            </p>


            {showAll && Object.keys(question['AnswerActions']).map((actionId: string, index: number) => {
                return <SingleAnswerAction
                    key={index}
                    actionId={actionId}
                    question={question}
                    savingProgress={savingProgress}
                    setSavingProgress={setSavingProgress}
                />
            })}</div>



    </div>
}

export default AnswerActionSummary